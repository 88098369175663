/* src/components/VerticalNavbar.css */
.vertical-navbar {
  max-width: 3rem;
  transition: max-width 0.3s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.2);
  padding-top: 6.2rem;
  z-index: 100;
}

.vertical-navbar:hover {
  max-width: 19rem;
  background-color: rgba(51, 51, 51, 0.95);

  .nav-item-sub {
    width: calc(100% - 2rem);
    padding-left: 1.5rem;
  };

  /* .coming-soon-badge {
    display: block;
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0.3s;
  } */

  /* .new-badge {
    display: block;
  } */
}
  
.nav-item {
  width: calc(100% - 1rem);
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  color: #eee;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-item:hover {
  background-color: rgba(36, 36, 36, 0.5);
}

.nav-item:active {
  background-color: rgba(51, 51, 51, 0.7); /* Darker background to indicate click */
  transform: scale(0.98); /* Slightly scale down to give a pressed effect */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add an inset shadow for a pressed effect */
}

.nav-item-sub {
  padding: 0.5rem;
}

.nav-item-sub:last-child {
  margin-bottom: 0.5rem;
}

.nav-icon {
  min-width: 2rem;
  height: 2rem;
}


.nav-icon-sub {
  min-width: 1.7rem;
  height: 1.7rem;
  margin-left: 0.15rem;
}

.nav-text {
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  color: #eee;
  font-size: 1.5rem;
}

.vertical-navbar:hover .nav-text {
  opacity: 1;
}

.nav-text-sub {
  font-size: 1.2rem;
}

.disabled-nav-item {
  opacity: 0.6;
  cursor: not-allowed;
  position: relative;
}

.disabled-icon {
  color: #bbb;
}

.coming-soon-badge {
  /* position: absolute; */
  /* right: 10px; */
  font-size: 0.7em;
  background-color: #666;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  /* display: none; */
  margin-left: 0.5rem;
  /* min-width: 68px; */
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.vertical-navbar:hover .coming-soon-badge {
  opacity: 1;
  visibility: visible;
}

.new-badge {
  /* position: absolute;
  right: 10px; */
  font-size: 0.7em;
  background-color: #007bff;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  /* display: none; */
  margin-left: 0.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.vertical-navbar:hover .new-badge {
  opacity: 1;
  visibility: visible;
}