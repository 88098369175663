/* Styles for Webkit browsers to mimic a hover-sensitive scrollbar similar to Firefox's behavior */

/* Customizes the scrollbar's overall look */
::-webkit-scrollbar {
  width: 5px; /* Thinner width when not hovered */
}

/* Hides the scrollbar track until hovered */
::-webkit-scrollbar-track {
  background-color: transparent; /* Makes the track invisible by default */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0; /* Medium grey, similar to Firefox's thumb */
  border-radius: 4px; /* Adjust for rounded corners */
  border: 4px solid transparent; /* Transparent border to maintain the thumb size */
}

/* Changes the track's color on hover to make it visible */
::-webkit-scrollbar-track:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Optionally, adjust the scrollbar thumb on hover for a visual feedback */
::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* A slightly darker grey on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Different color when active */
}
