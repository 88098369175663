.recipient-type-text {
  color: #ddd;
  flex: 0 0 auto;
}

.cc-button {
  color: #ddd;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.react-tagsinput {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.react-tagsinput-input {
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.react-tagsinput-tag {
  color: #fff;
  border: 1px solid #aaa;
  outline: none;
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-top: -6px;
  max-width: 100%;
  border-radius: 13px;
  display: inline-block;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

