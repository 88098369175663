.hamburger-menu {
  position: relative; /* Ensure the dropdown menu is positioned relative to this */
  z-index: 100; /* High z-index to ensure it's above other elements */
  margin-right: 10px;
}

.hamburger-icon {
  font-size: 2.3rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  z-index: 101; /* Slightly higher to be above the menu container */
}

.dropdown-menu {
  position: fixed; /* Use fixed positioning to stay on top */
  width: 200px;
  background-color: #f2f2f2;
  border: 1px solid #aaa;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 102; /* Ensure it's on top of everything else */
  overflow: hidden; /* Prevents the dropdown from affecting layout */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Transition for fade-in effect */
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}

.menu-item {
  display: flex;
  align-items: center; /* Vertically center the icon and text */
  padding: 12px 16px;
  color: #212121;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #d7d7d7;
}

.menu-icon {
  margin-right: 8px; /* Space between the icon and the text */
  font-size: 1.5rem;
}

.menu-item-container {
  position: relative;
  width: 100%;
}

.unit-dropdown {
  background-color: #f2f2f2;
  border: 1px solid #bbb;
  border-radius: 4px;
  color: #212121;
  margin: 0 1rem;
}

.unit-option {
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.unit-option:hover {
  background-color: #d7d7d7;
}

.unit-option:active {
  background-color: #c7c7c7;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.unit-option-active {
  background-color: #c7c7c7;
  cursor: default;
}

.unit-option-active:hover {
  background-color: #c7c7c7;
}

.unit-option-active:active {
  background-color: #c7c7c7;
  box-shadow: none;
}